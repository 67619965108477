import React from 'react';
import icon from '../images/hero_img_2.jpg'

function Hero() {

    const style = {
        'backgroundImage': `url(${icon})`
    }

    return (
        <div className='bg-cover bg-center h-screen w-screen' style={style}>
            <div className='h-screen bg-opacity-50 gb-black flex items-center justify-center'>
                <div className='mx-2 text-center'>
                    <h1 className='text-gitman-black font-extrabold text-4xl xs:text-5xl md:text-5xl'>La solución TOTAL para tu negocio</h1>
                </div>
            </div>
        </div>
    );
}

export default Hero;
import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const News = ({ list }) => {

    const bannerImageList = list.map(node => {
        return(
            <img className='w-screen h-auto' alt='news' src={node.node.frontmatter.banner} />
        );
    });

    var sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div id='banner-slider' className='pt-28 w-screen z-0'>
            <Slider {...sliderSettings}>
                {bannerImageList}
            </Slider>
        </div>
    );
};

export default News;
import React from 'react';
import CatalogItem from './catalog_item';
import GatsbyLink from 'gatsby-link';
import CustomButton from './custom_button';

const Catalog = ({ list }) => {
    const itemList = list.map(item => {
        const productUrl = `/catalog/${item.node.frontmatter.slug}`;
        return(
        <GatsbyLink className='w-full lg:max-w-[40%]' key={item.node.frontmatter.slug} to={productUrl}>
            <CatalogItem key={item.node.frontmatter.slug} productData={item.node.frontmatter} />
        </GatsbyLink>
        )
    });

    return (
        <div id='catalog-container' className='flex flex-col pt-32'>
            <h2 className='py-10 text-3xl font-semibold text-center'>Nuestro Catálogo</h2>
            <div id='catalog-item-container' className='pb-16 px-12 pt-16 w-full sm:px-20 flex flex-row flex-wrap gap-20 justify-between md:justify-center'>
                {itemList}
                <GatsbyLink className='min-w-[95%] md:min-w-[50%] lg:min-w-[40%] lg:max-w-[40%] h-56 border-2 border-gitman-black rounded hover:border-gitman-green' key='custom_button' to='/custom'>
                    <CustomButton />
                </GatsbyLink>
            </div>
        </div>
    );
};

export default Catalog;
import React from 'react';

const CatalogItem = ({ productData }) => {
    const productImage = productData.galeria ? productData.galeria[0] : '';
    return (
        <div className='flex flex-col bg-transparent rounded-lg w-full'>
            <img className='p-0' alt={productData.descripcion} src={productImage}></img>
            <h5 className='py-3 text-lg text-center'>{productData.title}</h5>
        </div>
    );
};

export default CatalogItem;
import * as React from "react";
import Layout from '../components/layout';
import Hero from '../components/hero';
import ProductList from '../components/product_list';
import News from '../components/news';
import Seo from '../components/seo';
import { graphql } from "gatsby";

//banner images 1900x740
const IndexPage = ({ data }) => {
  console.log(data.allMarkdownRemark.edges);
  const productList = data.allMarkdownRemark.edges.filter(node => node.node.frontmatter.slug);
  const bannerList = data.allMarkdownRemark.edges.filter(node => node.node.frontmatter.banner);

  return (
    <Layout>
      <div className="w-screen flex flex-col">
        <Hero />
        <News list={bannerList} />
        <ProductList list={productList}/>
      </div>
    </Layout>
  );
};

export const query = graphql`
{
  allMarkdownRemark(filter: {frontmatter: {activo: {eq: true}}}) {
    edges {
      node {
        frontmatter {
          title
          slug
          galeria
          banner
        }
      }
    }
  }
}
`

export default IndexPage;

export function Head() {
  return(
    <Seo />
  );
}